import request from "@/utils/request";

//获取活动分类
export function getActivityTypeList(){
    return request({
        url: '/appreq/home/list',
        headers: {
            isToken: false
        },
        method: 'get',
    })
}

//获取活动列表
export function getActivityList(params,data){
    return request({
        url: '/appreq/activity/getActivityList',
        method: 'post',
        headers: {
            isToken: false
        },
        data,
        params
    })
}

//获取通知公告列表
export function getNoticeList(params,data){
    return request({
        url: '/appreq/notice/list',
        method: 'get',
        headers: {
            isToken: false
        },
        params,
        data

    })
}

//获取商品列表
export function getShopGoods(params){
    return request({
        url: '/appreq/shopGoods/getGoosdList',
        headers: {
            isToken: false
        },
        method: 'get',
        params
    })
}

//获取积分排名
export function getUserListOrderByScore(params){
    return request({
        url: '/appreq/appUser/getUserListOrderByScore',
        headers: {
            isToken: false
        },
        method: 'get',
        params
    })
}

//获取站点list
export function getStationList(params){
    return request({
        url: '/appreq/station/list',
        headers: {
            isToken: false
        },
        method: 'get',
        params
    })
}

//获取信息统计
export function informationcount(data){
    return request({
        url: '/appreq/informationcount/informationcount',
        headers: {
            isToken: false
        },
        method: 'post',
        data
    })
}

//获取信息统计
export function informationcountByLant(data){
    return request({
        url: '/appreq/informationcount/informationcountByLant',
        headers: {
            isToken: false
        },
        method: 'post',
        data
    })
}

//获取底部信息
export function getButtomInfoAndLink(){
    return request({
        url: '/appreq/home/getButtomInfoAndLink',
        headers: {
            isToken: false
        },
        method: 'get',
    })
}


//获取首页背景图信息
export function getBanner(){
    return request({
        url: '/appreq/home/getBanner',
        headers: {
            isToken: false
        },
        method: 'get',
    })
}

